<template>
  <div id="page-user-view">
    <vs-alert color="danger" v-bind:title="$t('DriverNotFound')" :active.sync="ID_not_found">
      <span>record with ID : {{ $route.params.Id }} not found. </span>
      <span>
        <span>Check </span><router-link :to="{ name: 'Driver' }" class="text-inherit underline">All
          Drivers</router-link>
      </span>
    </vs-alert>

    <div id="user-data" v-if="FormData">
      <vx-card v-bind:title="$t('DriverInfo')" class="mb-base">
        <div class="vx-row">
          <!-- Avatar Col -->
          <!-- Information - Col 1 -->
          <div class="vx-col flex-1" id="account-info-col-1">
            <table>
              <tr>
                <td class="font-semibold">* {{ $t("FirstName") }}:</td>
                <!-- <td>{{ FormData.firstName }}</td> -->
                <td>
                  <vs-input class="w-full" v-model="FormData.firstName" v-validate="'required'" name="firstName" />
                  <span class="text-danger text-sm" v-show="errors.has('firstName')">{{ errors.first("firstName")
                  }}</span>
                </td>
              </tr>
              <tr>
                <td class="font-semibold">* {{ $t("LastName") }} :</td>
                <!-- <td>{{ FormData.lastName }}</td> -->
                <td style="width:75vw">
                  <vs-input class="w-full" v-model="FormData.lastName" v-validate="'required'" name="lastName" />
                  <span class="text-danger text-sm" v-show="errors.has('lastName')">{{ errors.first("lastName")
                  }}</span>
                </td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("VehicleOwnerFullName") }}:</td>
                <td>
                  <vs-input class="w-full" v-model="FormData.VehicleOWnerFullName" name="VehicleOwnerFullName" />
                  <span class="text-danger text-sm" v-show="errors.has('VehicleOwnerFullName')">{{
                    errors.first("VehicleOwnerFullName")
                  }}</span>
                </td>
              </tr>
              <tr>
                <td class="font-semibold"> * {{ $t("Email") }} :</td>
                <td style="width:75vw">
                  <vs-input class="w-full" v-model="FormData.Email" v-validate="'email'" name="Email" />
                  <span class="text-danger text-sm" v-show="errors.has('Email')">{{ errors.first("Email") }}</span>
                </td>
              </tr>
              <tr>
                <td class="font-semibold">* {{ $t("AccountStatus") }}:</td>
                <!-- <td>{{ FormData.AccountStatus }}</td> -->
                <td style="width:75vw">
                  <v-select style="padding:0px !important" :reduce="Code => Code.Code" class=" w-full"
                    :options="this.$options.AccountStatusJson" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    :get-option-label="option => $t(option.Code)" v-model="FormData.AccountStatus"
                    :ignoreAccent="true"></v-select>
                </td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("DriverLicense") }} :</td>
                <td>
                  <vs-input class="w-full" v-model="FormData.DriverLicense" name="DriverLicense"></vs-input>
                  <span class="text-danger text-sm" v-show="errors.has('DriverLicense')">{{
                    errors.first("DriverLicense")
                  }}</span>
                </td>
              </tr>
            </table>
          </div>

          <div class="vx-col flex-1" id="account-info-col-2">
            <table>
              <tr v-show="$acl.check('Accountant')">
                <td class="font-semibold">{{ $t("CreditLimit") }} :</td>
                <td>
                  <vs-input class="w-full" v-model="FormData.CreditLimit" name="CreditLimit" type="number"
                    @keypress="isNumber($event)" />
                  <!-- <span
                    class="text-danger text-sm"
                    v-show="errors.has('CreditLimit')"
                    >{{ errors.first("CreditLimit") }}</span
                  > -->
                </td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("FreeTrips") }} :</td>
                <td>
                  <vs-input class="w-full" v-model="FormData.FreeTrips" name="FreeTrips" @keypress="isNumber($event)"
                    type="number" />
                  <span class="text-danger text-sm" v-show="errors.has('FreeTrips')">{{ errors.first("FreeTrips")
                  }}</span>
                </td>
              </tr>

              <tr>
                <td class="font-semibold">{{ $t("BirthDate") }} :</td>
                <td style="width:75vw">
                  <datepicker :format="format" v-model="FormData.BirthDate" name="BirthDate" :language="DatepickerLang">
                  </datepicker>
                </td>
              </tr>
              <tr>
                <td class="font-semibold">* {{ $t("NationalID") }} :</td>
                <td>
                  <vs-input class="w-full" v-model="FormData.NationalID" name="NationalID"
                    v-validate="'required'"></vs-input>
                  <span class="text-danger text-sm" v-show="errors.has('NationalID')">{{ errors.first("NationalID")
                  }}</span>
                </td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("TaxID") }} :</td>
                <td>
                  <vs-input class="w-full" v-model="FormData.TaxID" name="TaxID"></vs-input>
                  <span class="text-danger text-sm" v-show="errors.has('TaxID')">{{ errors.first("TaxID") }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <label class="font-semibold">{{ $t("State") }}</label>
                </td>
                <td>
                  <v-select :get-option-label="option => $t(option[StateWithLocale])" class="mt-1 w-full p-0"
                    v-validate="'required'" name="State" v-model="FormData.state" @input="GetCityList(FormData.state)"
                    :reduce="admin1Name_en => admin1Name_en.admin1Name_en" :options="StateList"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'" />

                  <span class="text-danger text-sm" v-show="errors.has('State')">{{ errors.first("State") }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <label class="font-semibold">{{ $t("City") }}</label>
                </td>
                <td>
                  <v-select :get-option-label="option => $t(option[CityWithLocale])" class="mt-1 w-full p-0"
                    v-validate="'required'" name="City" v-model="FormData.city"
                    :reduce="admin2Name_en => admin2Name_en.admin2Name_en" :options="CityList"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'" />

                  <span class="text-danger text-sm" v-show="errors.has('City')">{{ errors.first("City") }}</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </vx-card>

      <vx-card v-bind:title="$t('BankAccountInfo')" class="mb-base">
        <div class="vx-row">
          <!-- Avatar Col -->
          <!-- Information - Col 1 -->
          <div class="vx-col flex-1" id="account-info-col-1">
            <!-- Avatar -->
            <table>
              <tr>
                <td class="font-semibold">{{ $t("FullName") }} :</td>

                <td style="width:75vw">
                  <vs-input class="w-full" v-model="BankData.FullName" />
                  <!-- <span
                    class="text-danger text-sm"
                    v-show="errors.has('FullName')"
                    >{{ errors.first("FullName") }}</span
                  > -->
                </td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("BankName") }}:</td>

                <td>
                  <vs-input class="w-full" v-model="BankData.BankName" />
                </td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("BranchName") }}:</td>
                <!-- <td>{{ BankData.BranchName}}</td> -->
                <td>
                  <vs-input class="w-full" v-model="BankData.BranchName" />
                  <!-- <span
                    class="text-danger text-sm"
                    v-show="errors.has('BranchName')"
                    >{{ errors.first("BranchName") }}</span
                  > -->
                </td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("AccountNumber") }} :</td>
                <!-- <td>{{ BankData.AccountNumber  }}</td> -->
                <td>
                  <vs-input @keypress="isNumber($event)" class="w-full" v-model="BankData.AccountNumber" />
                  <!-- <span
                    class="text-danger text-sm"
                    v-show="errors.has('AccountNumber')"
                    >{{ errors.first("AccountNumber") }}</span
                  > -->
                </td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("SWIFT") }} :</td>
                <!-- <td>{{ BankData.SWIFT }}</td> -->
                <td>
                  <vs-input class="w-full" v-model="BankData.SWIFT" />
                  <!-- <span
                    class="text-danger text-sm"
                    v-show="errors.has('SWIFT')"
                    >{{ errors.first("SWIFT") }}</span
                  > -->
                </td>
              </tr>
            </table>
          </div>
          <div class="vx-col flex-1" id="account-info-col-2">
            <table>
              <tr>
                <td class="font-semibold">{{ $t("BankAddress") }} :</td>
                <!-- <td>{{ BankData.BankAddress  }}</td> -->
                <td style="width:75vw">
                  <vs-input class="w-full" v-model="BankData.BankAddress" />
                  <!-- <span
                    class="text-danger text-sm"
                    v-show="errors.has('BankAddress')"
                    >{{ errors.first("BankAddress") }}</span
                  > -->
                </td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("City") }} :</td>
                <!-- <td>{{ BankData.City  }}</td> -->
                <td>
                  <vs-input class="w-full" v-model="BankData.City" />
                  <!-- <span
                    class="text-danger text-sm"
                    v-show="errors.has('City')"
                    >{{ errors.first("City") }}</span
                  > -->
                </td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("State") }} :</td>
                <!-- <td>{{ BankData.State  }}</td> -->
                <td>
                  <vs-input class="w-full" v-model="BankData.State" />
                  <!-- <span
                    class="text-danger text-sm"
                    v-show="errors.has('State')"
                    >{{ errors.first("State") }}</span
                  > -->
                </td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("Country") }} :</td>
                <!-- <td>{{ BankData.Country  }}</td> -->
                <td>
                  <vs-input class="w-full" v-model="BankData.Country" />
                  <!-- <span
                    class="text-danger text-sm"
                    v-show="errors.has('Country')"
                    >{{ errors.first("Country") }}</span
                  > -->
                </td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("ZipCode") }} :</td>
                <!-- <td>{{ BankData.SWIFT }}</td> -->
                <td>
                  <vs-input class="w-full" v-model="BankData.ZipCode" />
                  <!-- <span
                    class="text-danger text-sm"
                    v-show="errors.has('ZipCode')"
                    >{{ errors.first("ZipCode") }}</span
                  > -->
                </td>
              </tr>
            </table>
          </div>
        </div>
      </vx-card>

      <vx-card v-bind:title="$t('VehicleInfo')" class="mb-base">
        <div class="vx-row">
          <div class="vx-col flex-1" id="account-info-col-2">
            <table>
              <tr>
                <td class="font-semibold">{{ $t("LicensePlate") }}:</td>
                <!-- <td>{{ FormData.LicensePlate }}</td> -->
                <td>
                  <vs-input class="w-full" v-model="FormData.LicensePlate" v-validate="'required'" name="LicensePlate" />
                  <span class="text-danger text-sm" v-show="errors.has('LicensePlate')">{{ errors.first("LicensePlate")
                  }}</span>
                </td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("VehicleType") }}:</td>
                <td style="width:75vw">

                  <v-select style="padding:0px !important" label="VehiclesTypeName" class="mt-1 w-full"
                    v-model="FormData.VehiclesTypeID" :options="VehiclesTypes" v-validate="'required'"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'" :reduce="ID => ID.Id">
                  </v-select>
                </td>
              </tr>


            </table>
          </div>
          <div class="vx-col flex-1" id="account-info-col-2">
            <table>
              <tr>
                <td class="font-semibold">{{ $t("VehicleMake") }} :</td>
                <td style="width:75vw">
                  <vs-input class="w-full" v-model="FormData.VehicleMake" name="VehicleMake" />
                  <span class="text-danger text-sm" v-show="errors.has('VehicleMake')">{{ errors.first("VehicleMake")
                  }}</span>
                </td>
              </tr>

              <tr>
                <td class="font-semibold">{{ $t("VehicleColor") }} :</td>
                <td>
                  <vs-input class="w-full" v-model="FormData.VehicleColor" name="VehicleColor" />
                  <span class="text-danger text-sm" v-show="errors.has('VehicleColor')">{{ errors.first("VehicleColor")
                  }}</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </vx-card>

      <vx-card v-bind:title="$t('ExpiredDocsDate')" class="mb-base">
        <div class="vx-row">
          <div class="vx-col flex-1" id="account-info-col-2">
            <table>
              <tr>
                <td tyle="width:50vw" class="font-semibold">
                 * {{ $t("PersonalIDExp") }} :
                </td>
                <td>
                  <datepicker :format="format" v-model="FormData.PersonalIDExp" name="PersonalIDExp"
                    :language="DatepickerLang"></datepicker>
                </td>
              </tr>
              <tr>
                <td tyle="width:50vw" class="font-semibold">
                  {{ $t("DriverLicenseExp") }} :
                </td>
                <td>
                  <datepicker :format="format" v-model="FormData.DriverLicenseExp" name="DriverLicenseExp"
                    :language="DatepickerLang"></datepicker>
                </td>
              </tr>

              <tr>
                <td tyle="width:50vw" class="font-semibold">
                  {{ $t("CarRegistrationExp") }} :
                </td>
                <td>
                  <datepicker :format="format" v-model="FormData.CarRegistrationExp" name="CarRegistrationExp"
                    :language="DatepickerLang"></datepicker>
                </td>
              </tr>
            </table>
          </div>
          <div class="vx-col w-full flex" id="account-manage-buttons">
            <div style="padding: 10px;">
              <vs-button class="ml-auto mt-2" @click="confirmSave" :disabled="!validateForm">
                {{ $t("SaveChanges") }}
              </vs-button>
            </div>
            <div style="padding: 10px;">
              <vs-button class="ml-auto mt-2" type="border" color="warning" icon-pack="feather" @click="Back">{{
                $t("Back")
              }}</vs-button>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import moduleDriver from "@/store/driver/moduleDriver.js";
import Datepicker from "vuejs-datepicker";
import AccountStatus_JSON from "./json/accountStatus.json";
import Vehicle_JSON from "./json/vehicles.json";
import VacationMode_JSON from "./json/vacationMode.json";
import VehiclOptions_JSON from "./json/vehiclesOptions.json";
import VehiclOptions1_JSON from "./json/vehiclesOptions1.json";
import VehiclOptions2_JSON from "./json/vehiclesOptions2.json";
import { en, ar } from "vuejs-datepicker/src/locale";
import moduleCountry from "@/store/countrySetting/moduleCountry.js";
import moduleVehiclesType from "@/store/VehiclesSetting/VehiclesType/moduleVehiclesType";


export default {
  AccountStatusJson: AccountStatus_JSON,
  VehicleJson: Vehicle_JSON,
  VehiclOptionseJson: VehiclOptions_JSON,
  VehiclOptionse1Json: VehiclOptions1_JSON,
  VehiclOptionse2Json: VehiclOptions2_JSON,
  VacationModeJson: VacationMode_JSON,
  Option: [],
  data() {
    return {
      CityList: [],
      StateList: [],
      format: "dd/MM/yyyy",
      ar: ar,
      en: en,
      FormData: null,
      BankData: {
        Country: "",
        State: "",
        City: "",
        BankAddress: "",
        SWIFT: "",
        AccountNumber: "",
        BranchName: "",
        FullName: "",
        BankName: "",
        ZipCode: "",
        VehiclesTypeID: "",

      },
      ID_not_found: false
    };
  },
  components: {
    Datepicker,
    moduleVehiclesType
  },
  computed: {
    VehiclesTypes() {
      return this.$store.state.VehiclesTypeList.VehiclesTypes;
    },
    StateWithLocale() {
      let State = "admin1Name_en";
      if (this.$i18n.locale == "ar") {
        State = "admin1Name_ar";
      }
      return State;
    },
    CityWithLocale() {
      let City = "admin2Name_en";
      if (this.$i18n.locale == "ar") {
        City = "admin2Name_ar";
      }
      return City;
    },
    validateForm() {
      return (
        !this.errors.any() &&
        this.FormData.firstName != "" &&
        this.FormData.lastName != "" &&
        this.FormData.AccountStatus != null &&
        this.FormData.NationalID != null &&
        this.FormData.NationalID != "" &&
        this.FormData.PersonalIDExp != "" &&
        this.FormData.BirthDate != null
      );
    },
    DatepickerLang() {
      var SelectedLanguage = {};

      if (this.$i18n.locale == "ar") {
        SelectedLanguage = this.ar;
      } else {
        SelectedLanguage = this.en;
      }
      return SelectedLanguage;
    }
  },

  methods: {
    GetCityList(StateName) {
      var Model = {};
      Model.StateName = StateName;
      this.$store
        .dispatch("CountryList/GetCityList", Model)
        .then(res => {
          debugger;
          this.CityList = res.data;
        })
        .catch(() => { });
    },
    isNumber: function (evt) {
      evt = evt || window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    changeVehicleType() {
      if (
        this.FormData.VehicleType == "TT4" ||
        this.FormData.VehicleType == "TT5" ||
        this.FormData.VehicleType == "TT3"
      ) {
        this.Option = this.VehiclOptionse1Json;
      } else if (this.FormData.VehicleType == "TT8") {
        this.Option = this.VehiclOptionse2Json;
      } else {
        this.Option = [];
      }
    },
    Back() {
      this.$router.go(-1);
    },
    save_changes() {
      if (!this.validateForm) {
        window.showFillData();
        return;
      }

      this.$vs.loading();
      let AdminUserId = JSON.parse(localStorage.getItem("userInfo")).Id;
      // if(!this.validateForm) return
      this.FormData.Id = this.$route.params.Id;
      let UpdateBiadjoObj = {};
      UpdateBiadjoObj.AdminUserId = AdminUserId;
      UpdateBiadjoObj.DriverAccount = JSON.stringify(this.FormData);
      this.$store
        .dispatch("DriverList/update", UpdateBiadjoObj)
        .then(response => {
          if (response.status == 200) {
            window.showSuccess();
            this.$vs.loading.close();
          } else {
            window.showError();
            this.$vs.loading.close();
          }
        })
        .catch(err => {
          this.$vs.loading.close();
          window.showError();
          console.log(err.response);
        });
      this.BankData.DriverID = this.$route.params.Id;
      this.BankData.AdminUserId = AdminUserId;
      this.$store
        .dispatch("DriverList/updateBankAccountInfoData", this.BankData)
        .then(response => {
          if (response.status == 200) {
            this.$vs.loading.close();
          }
        })
        .catch(err => {
          this.$vs.loading.close();
          window.showError();
          console.log(err.response);
        });
      this.$vs.loading.close();
    },

    confirmSave() {
      if (!this.validateForm) {
        window.showFillData();
        return;
      }
      this.$vs.dialog({
        type: "confirm",
        color: "#27ABBE",
        title: this.$t("ConfirmChange"),
        text: this.$t("YouAreAboutToChangeDriverData"),
        accept: this.save_changes,
        acceptText: this.$t("Save"),
        cancelText: this.$t("Cancel")
      });
    }
  },
  created() {
    if (!moduleVehiclesType.isRegistered) {
      this.$store.registerModule("VehiclesTypeList", moduleVehiclesType);
      moduleVehiclesType.isRegistered = true;
    }
    this.$vs.loading();
    if (!moduleDriver.isRegistered) {
      this.$store.registerModule("DriverList", moduleDriver);
      moduleDriver.isRegistered = true;
    }
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }
    this.$store
      .dispatch("VehiclesTypeList/fetchAllVehiclesTypeList")

    var Model = {};
    Model.CountryCode = "YE";
    this.$store
      .dispatch("CountryList/GetStateList", Model)
      .then(res => {
        debugger;
        this.StateList = res.data;
      })
      .catch(() => { });

    var driver = {};
    driver.Id = this.$route.params.Id;
    this.$store
      .dispatch("DriverList/GetDriverById", driver)
      .then(response => {
        if (response.status == 200) {
          if (response.data.length > 0) {
            this.FormData = response.data[0];
            if (this.FormData.state) {
              this.GetCityList(this.FormData.state);
            }
            window.notAllowedToAccessCurrentPage(this.FormData.AccountStatus);
          } else {
            this.$vs.loading.close();
            this.ID_not_found = true;
          }
        }
      })
      .catch(() => {
        window.showError();
      });

    this.$store
      .dispatch("DriverList/bankAccountInfoData", driver.Id)
      .then(response => {
        this.BankData = response.data[0];
        this.$vs.loading.close();
      });
  }
};
</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}

#page-user-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: 0.8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}

@media screen and (min-width: 1201px) and (max-width: 1211px),
only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }
}
</style>
