



import state from "./moduleVehiclesTypeState.js";
import  mutations  from "./moduleVehiclesTypeMutations.js";
import actions from "./moduleVehiclesTypeActions.js";
import getters from "./moduleVehiclesTypeGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
